import { useState, useEffect } from "react";
import * as React from "react";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

type Props = {
  delay?: number;
  size?: number;
  [rest: string]: unknown;
};

function Loading({ delay, size, ...rest }: Props): React.ReactElement {
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        setIsLoaderVisible(true);
      },
      Number.isInteger(delay) ? delay : 300
    );

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <Box textAlign="center" {...rest}>
      {isLoaderVisible ? <CircularProgress size={size || 30} /> : null}
    </Box>
  );
}

export default Loading;
