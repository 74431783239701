import * as React from "react";
import { MenuItem, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

type Props = {
  name: string;
  label: string;
  labelKey?: string;
  control: any;
  required?: boolean;
  disabled?: boolean;
  options: {
    id: string | number;
    label?: React.ReactNode;
    disabled?: boolean;
  }[];
  onChange?: (event: any) => void;
  onOptionClick?: (option: any) => void;
};

function FormSelect({
  name,
  label,
  labelKey = "label",
  control,
  required,
  disabled,
  options,
  onChange,
  onOptionClick,
}: Props): React.ReactElement {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field }) => {
        return (
          <TextField
            {...field}
            select
            label={label}
            required={required}
            disabled={disabled}
            variant="standard"
            InputLabelProps={{ shrink: true }}
            fullWidth
            onChange={(event: any) => {
              field.onChange(event);
              onChange && onChange(event);
            }}
          >
            {options.map((option: any) => (
              <MenuItem
                key={option.id}
                value={option.id}
                disabled={option.disabled}
                onClick={() => onOptionClick && onOptionClick(option)}
              >
                {option[labelKey]}
              </MenuItem>
            ))}
          </TextField>
        );
      }}
    />
  );
}

export default FormSelect;
