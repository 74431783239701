import * as React from "react";
import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";

type Props = {
  name: string;
  control: any;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  type?: string;
  sx?: any;
  multiline?: boolean;
  rows?: number;
  minRows?: number;
  maxRows?: number;
  step?: number;
  onChange?: (event: any) => void;
};

function FormTextField({
  name,
  label,
  control,
  required,
  disabled,
  readOnly,
  type = "text",
  sx,
  multiline,
  rows,
  minRows,
  maxRows,
  step,
  onChange,
}: Props): React.ReactElement {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field }) => (
        <TextField
          {...field}
          onChange={(event: any) => {
            field.onChange(event);
            onChange && onChange(event);
          }}
          variant="standard"
          label={label}
          fullWidth
          required={required}
          disabled={disabled}
          type={type}
          sx={sx}
          multiline={multiline}
          rows={rows}
          minRows={minRows}
          maxRows={maxRows}
          InputProps={{
            readOnly,
          }}
          inputProps={{ step }}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
}

export default FormTextField;
