import * as React from "react";
import { FormControlLabel, Checkbox } from "@mui/material";
import { Controller } from "react-hook-form";

type Props = {
  name: string;
  control: any;
  label: string;
  required?: boolean;
  disabled?: boolean;
};

function FormCheckbox({
  name,
  label,
  control,
  required,
  disabled,
}: Props): React.ReactElement {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Checkbox
              {...field}
              onChange={(e: any) => field.onChange(e.target.checked)}
              checked={field.value}
              required={required}
            />
          }
          label={label}
          disabled={disabled}
        />
      )}
    />
  );
}

export default FormCheckbox;
