import * as React from "react";
import { useTable, Column } from "react-table";
import { Link as RouterLink } from "react-router-dom";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  Paper,
  TableFooter,
  Box,
} from "@mui/material";

import { Loading, Pagination } from ".";

type Props = {
  loading?: boolean;
  data: Record<string, unknown>[];
  columns: Column<Record<string, unknown>>[];
  redirectUrl?: string | Record<string, string>;
  redirectId?: string | Record<string, string>;
  linkHeaders?: string[];
  searchParams?: string;
  page?: number;
  count?: number;
  onPageChange?: (selectedPage: number) => void;
};

function DataTable({
  loading,
  data,
  columns,
  redirectUrl,
  redirectId = "id",
  linkHeaders,
  searchParams,
  page,
  count,
  onPageChange,
}: Props): React.ReactElement {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <TableContainer component={Paper} sx={{ mt: 2, position: "relative" }}>
      <Table className="stripped" size="small" {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // eslint-disable-next-line react/jsx-key
                <TableCell
                  {...column.getHeaderProps((props) => ({
                    ...props,
                    style: { whiteSpace: "nowrap" },
                  }))}
                >
                  {column.render("Header")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>

        <TableBody {...getTableBodyProps()}>
          {loading ? (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <Loading />
              </TableCell>
            </TableRow>
          ) : (
            rows.map((row) => {
              prepareRow(row);
              return (
                // eslint-disable-next-line react/jsx-key
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <TableCell
                        {...cell.getCellProps((props) => ({
                          ...props,
                          style: { whiteSpace: "nowrap" },
                        }))}
                      >
                        {linkHeaders &&
                        linkHeaders.some(
                          (element) => cell.column.id === element
                        ) ? (
                          <Link
                            component={RouterLink}
                            to={`${
                              !redirectUrl
                                ? "view"
                                : typeof redirectUrl === "string"
                                ? redirectUrl
                                : redirectUrl[cell.column.id]
                            }/${
                              typeof redirectId === "string"
                                ? redirectId
                                    .split(".")
                                    .reduce((o, i): any => o[i], row.original)
                                : redirectId[cell.column.id]
                                    .split(".")
                                    .reduce((o, i): any => o[i], row.original)
                            }${searchParams ? "?" + searchParams : ""}`}
                          >
                            {cell.render("Cell")}
                          </Link>
                        ) : (
                          cell.render("Cell")
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
          )}
          {!loading && rows.length === 0 ? (
            <TableRow>
              <TableCell sx={{ textAlign: "center" }} colSpan={columns.length}>
                No data found
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
        {onPageChange && (
          <TableFooter
            sx={{ display: { xs: "none", md: "table-footer-group" } }}
          >
            <TableRow>
              <TableCell colSpan={columns.length}>
                <Pagination page={page} count={count} onChange={onPageChange} />
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
      {onPageChange && (
        <Box sx={{ display: { md: "none" }, position: "sticky", left: 0 }}>
          <Pagination page={page} count={count} onChange={onPageChange} />
        </Box>
      )}
    </TableContainer>
  );
}

export default DataTable;
