import * as React from "react";
import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { isValid } from "date-fns";
import { DatePicker } from "@mui/x-date-pickers";

type Props = {
  name: string;
  label: string;
  control: any;
  required?: boolean;
  disabled?: boolean;
};

function FormDatePicker({
  name,
  label,
  control,
  required,
  disabled,
}: Props): React.ReactElement {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required, validate: isValid }}
      render={({ field }) => (
        <DatePicker
          {...field}
          label={label}
          value={field.value}
          onChange={(date: Date | null) => field.onChange(date)}
          disabled={disabled}
          renderInput={(params: any) => (
            <TextField
              {...params}
              variant="standard"
              fullWidth
              required={required}
            />
          )}
          closeOnSelect
          InputAdornmentProps={{
            sx: { marginLeft: "-32px", displayPrint: "none" },
          }}
        />
      )}
    />
  );
}

export default FormDatePicker;
