import * as React from "react";
import { NavLink } from "react-router-dom";
import {
  Box,
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const categories = [
  {
    name: "Reports",
    links: [
      {
        name: "dashboard",
        label: "Dashboard",
        to: "",
      },
      {
        name: "stock-summary",
        label: "Stock Summary",
        to: "/stock-summary",
      },
      {
        name: "production-summary",
        label: "Production Summary",
        to: "/production-summary",
      },
    ],
  },
  {
    name: "Masters",
    links: [
      {
        name: "items",
        label: "Items",
        to: "/items",
      },
      {
        name: "parties",
        label: "Parties",
        to: "/parties",
      },
    ],
  },
  {
    name: "Vouchers",
    links: [
      {
        name: "purchases",
        label: "Purchases",
        to: "/purchases",
      },
      {
        name: "job",
        label: "Jobs",
        to: "/jobs",
      },
      {
        name: "productions",
        label: "Productions",
        to: "/productions",
      },
      {
        name: "delivery-challans",
        label: "Delivery Challans",
        to: "/delivery-challans",
      },
      {
        name: "material-ins",
        label: "Material In",
        to: "/material-ins",
      },
      {
        name: "issued-spares",
        label: "Issued Spares",
        to: "/issued-spares",
      },
      // {
      //   name: "sales-orders",
      //   label: "Sales Orders",
      //   to: "/sales-orders",
      // },
    ],
  },
];

type Props = {
  drawerWidth: number;
  mobileOpen?: boolean;
  handleDrawerToggle?: () => void;
  roleAccess?: string[];
  displayPrint?: string;
  [rest: string]: unknown;
};

function Sidebar({
  drawerWidth,
  mobileOpen,
  handleDrawerToggle,
  displayPrint,
  roleAccess,
}: Props): React.ReactElement {
  const drawer = (
    <Box>
      <Toolbar />
      <List component="nav" dense sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
        {categories.map((category) => (
          <React.Fragment key={category.name}>
            <ListItem>
              <ListItemText
                primary={category.name}
                primaryTypographyProps={{ fontWeight: "bolder" }}
              />
            </ListItem>
            <List disablePadding dense>
              {category.links.map((link) =>
                !roleAccess || roleAccess.some((k) => k === link.name) ? (
                  <ListItem
                    button
                    component={NavLink}
                    to={link.to}
                    key={link.name}
                    sx={{
                      pl: 4,
                      "&.active": {
                        borderLeft: "3px solid",
                        borderLeftColor: "primary.main",
                      },
                    }}
                  >
                    {/* <ListItemIcon>
            {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
          </ListItemIcon> */}
                    <ListItemText primary={link.label} />
                  </ListItem>
                ) : null
              )}
            </List>
          </React.Fragment>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      displayPrint={displayPrint}
      sx={{
        width: { md: drawerWidth },
        flexShrink: { md: 0 },
      }}
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            border: "none",
            // bgcolor: "primary.main",
            // bgcolor: "transparent",
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            border: "none",
            // bgcolor: "primary.main",
            bgcolor: "transparent",
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

export default Sidebar;
