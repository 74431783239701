import * as React from "react";
import { Pagination as MuiPagination } from "@mui/material";
import { Box } from "@mui/system";

type Props = {
  page?: number;
  count?: number;
  onChange: (...args: any[]) => any;
};

function Pagination({ page, count, onChange }: Props): React.ReactElement {
  return (
    <Box sx={{ textAlign: { xs: "center", md: "right" }, padding: 1 }}>
      {count ? (
        <MuiPagination
          count={Math.ceil(count)}
          color="primary"
          onChange={(_, selected: number) => {
            const topViewElement = document.getElementById("topView");
            if (topViewElement) topViewElement.scrollIntoView();
            onChange(selected);
          }}
          page={page}
          sx={{ display: "inline-block" }}
        />
      ) : null}
    </Box>
  );
}

export default Pagination;
