import { useState, useRef, lazy, Suspense, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import cookie from "cookie";
import { useAuth0 } from "@auth0/auth0-react";
import { SnackbarKey, useSnackbar } from "notistack";
import { useOnline } from "rooks";

import RequireAuth from "../auth/require-auth";
import { roleAccess } from "../constants";
import { Loading, PageContainer } from "../components";

const DashboardPage = lazy(() => import("./dashboard"));
const ItemsPage = lazy(() => import("./items"));
const PartiesPage = lazy(() => import("./parties"));
const PurchasesPage = lazy(() => import("./purchases"));
const JobsPage = lazy(() => import("./jobs"));
const ProductionsPage = lazy(() => import("./productions"));
const DeliveryChallansPage = lazy(() => import("./delivery-challans"));
const MaterialInPage = lazy(() => import("./material-ins"));
const IssuedSparesPage = lazy(() => import("./issued-spares"));
const SalesOrdersPage = lazy(() => import("./sales-orders"));
const StockSummaryPage = lazy(() => import("./stock-summary"));
const ProductionSummaryPage = lazy(() => import("./production-summary"));
const LoginPage = lazy(() => import("./login"));

export default function App(): React.ReactElement {
  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    user = {},
  } = useAuth0();

  const online = useOnline();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const internetSnackbarKey = useRef<SnackbarKey | undefined>(undefined);

  useEffect(() => {
    if (!online) {
      closeSnackbar(internetSnackbarKey.current);
      internetSnackbarKey.current = enqueueSnackbar("No Internet", {
        persist: true,
        variant: "error",
      });
    } else if (internetSnackbarKey.current) {
      closeSnackbar(internetSnackbarKey.current);
      enqueueSnackbar("Internet On", {
        variant: "success",
      });
      internetSnackbarKey.current = undefined;
    }
  }, [online]);

  const [tokenLoading, setTokenLoading] = useState(true);
  const userRoles = user["https://orionfabrics.in/roles"];
  const role: string =
    Array.isArray(userRoles) && userRoles.length ? userRoles[0] : "";

  if (isLoading) return <Loading marginY={2} />;

  const callApi = async () => {
    try {
      const token = await getAccessTokenSilently();
      document.cookie = cookie.serialize("token", String(token), {
        maxAge: 24 * 60 * 60 * 1000,
      });
      setTokenLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  if (isAuthenticated) {
    callApi();
    if (tokenLoading) return <Loading marginY={2} />;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PageContainer
            sidebar={isAuthenticated}
            roleAccess={roleAccess[role]}
          />
        }
      >
        <Route
          index
          element={
            <RequireAuth isAuthenticated={isAuthenticated}>
              <Suspense fallback={<Loading marginY={2} />}>
                <DashboardPage />
              </Suspense>
            </RequireAuth>
          }
        />

        <Route
          path="items/*"
          element={
            <RequireAuth isAuthenticated={isAuthenticated}>
              <Suspense fallback={<Loading marginY={2} />}>
                <ItemsPage />
              </Suspense>
            </RequireAuth>
          }
        />

        <Route
          path="parties/*"
          element={
            <RequireAuth isAuthenticated={isAuthenticated}>
              <Suspense fallback={<Loading marginY={2} />}>
                <PartiesPage />
              </Suspense>
            </RequireAuth>
          }
        />

        <Route
          path="purchases/*"
          element={
            <RequireAuth isAuthenticated={isAuthenticated}>
              <Suspense fallback={<Loading marginY={2} />}>
                <PurchasesPage />
              </Suspense>
            </RequireAuth>
          }
        />

        <Route
          path="jobs/*"
          element={
            <RequireAuth isAuthenticated={isAuthenticated}>
              <Suspense fallback={<Loading marginY={2} />}>
                <JobsPage />
              </Suspense>
            </RequireAuth>
          }
        />

        <Route
          path="productions/*"
          element={
            <RequireAuth isAuthenticated={isAuthenticated}>
              <Suspense fallback={<Loading marginY={2} />}>
                <ProductionsPage roleAccess={roleAccess[role]} />
              </Suspense>
            </RequireAuth>
          }
        />

        <Route
          path="delivery-challans/*"
          element={
            <RequireAuth isAuthenticated={isAuthenticated}>
              <Suspense fallback={<Loading marginY={2} />}>
                <DeliveryChallansPage />
              </Suspense>
            </RequireAuth>
          }
        />

        <Route
          path="material-ins/*"
          element={
            <RequireAuth isAuthenticated={isAuthenticated}>
              <Suspense fallback={<Loading marginY={2} />}>
                <MaterialInPage />
              </Suspense>
            </RequireAuth>
          }
        />

        <Route
          path="issued-spares/*"
          element={
            <RequireAuth isAuthenticated={isAuthenticated}>
              <Suspense fallback={<Loading marginY={2} />}>
                <IssuedSparesPage />
              </Suspense>
            </RequireAuth>
          }
        />

        <Route
          path="sales-orders/*"
          element={
            <RequireAuth isAuthenticated={isAuthenticated}>
              <Suspense fallback={<Loading marginY={2} />}>
                <SalesOrdersPage />
              </Suspense>
            </RequireAuth>
          }
        />

        <Route
          path="stock-summary/*"
          element={
            <RequireAuth isAuthenticated={isAuthenticated}>
              <Suspense fallback={<Loading marginY={2} />}>
                <StockSummaryPage />
              </Suspense>
            </RequireAuth>
          }
        />

        <Route
          path="production-summary/*"
          element={
            <RequireAuth isAuthenticated={isAuthenticated}>
              <Suspense fallback={<Loading marginY={2} />}>
                <ProductionSummaryPage />
              </Suspense>
            </RequireAuth>
          }
        />

        <Route
          path="login"
          element={
            <Suspense fallback={<Loading marginY={2} />}>
              <LoginPage isAuthenticated={isAuthenticated} />
            </Suspense>
          }
        ></Route>
      </Route>
    </Routes>
  );
}
