import * as React from "react";
import cn from "clsx";

type Props = {
  className?: string;
};

function Footer({ className }: Props): React.ReactElement {
  return (
    <div className={className}>
      <div className="row"></div>
      <footer className={cn("navbar fixed-bottom", className)}>Footer</footer>
    </div>
  );
}

export default Footer;
