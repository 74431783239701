import * as React from "react";
import { Navigate } from "react-router-dom";

type Props = {
  children: React.ReactNode;
  isAuthenticated: boolean;
};

function RequireAuth({ children, isAuthenticated }: Props): any {
  return isAuthenticated ? children : <Navigate to="/login" />;
}

export default RequireAuth;
