/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import formatDate from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { compareWeight } from "./utils";
import { Column } from "react-table";

export const pageSize = 15;
export const glmScope = 0.015;

export const itemGroups = {
  YARN: { id: 1, name: "Yarn" },
  FABRIC: { id: 2, name: "Fabric" },
  SPARE: { id: 3, name: "Spare" },
};

export const deliveryChallansColumns = [
  {
    Header: "Challan No.",
    id: "challanNo",
    accessor: "challanNo",
  },
  {
    Header: "Job Work",
    id: "isForJobWork",
    accessor: (row: any): string => (row.isForJobWork ? "Yes" : ""),
  },
  {
    Header: "Date",
    id: "challanDate",
    accessor: (row: any): string =>
      row.challanDate
        ? formatDate(parseISO(row.challanDate), "dd/MM/yyyy")
        : "",
  },
  {
    Header: "Party",
    id: "party",
    accessor: (row: any): string => (row.party ? row.party.name : ""),
  },
  {
    Header: "Quality Name",
    id: "qualityName",
    accessor: (row: any): string => row.qualityName || "",
  },
  {
    Header: "Total Length",
    id: "totalLength",
    accessor: (row: any): string =>
      row.totalLength ? (+row.totalLength).toFixed(2) + " m" : "",
  },
  {
    Header: "Total Pieces",
    id: "totalPieces",
    accessor: (row: any): string => row.totalPieces || "",
  },
];

export const partiesColumns = [
  {
    Header: "Party Name",
    id: "name",
    accessor: "name",
  },
  {
    Header: "GSTIN",
    id: "gstin",
    accessor: (row: any): string => row.gstin || "",
  },
];

export const itemsColumns = [
  {
    Header: "Item Name",
    id: "name",
    accessor: "name",
  },
  {
    Header: "Item Group",
    id: "itemGroup",
    accessor: (row: any): string => row.itemGroup.name,
  },
  {
    Header: "Unit",
    id: "unit",
    accessor: (row: any): string => row.unit.symbol,
  },
];

export const purchasesColumns = [
  {
    Header: "Date",
    id: "voucherDate",
    accessor: (row: any): string =>
      row.voucherDate
        ? formatDate(parseISO(row.voucherDate), "dd/MM/yyyy")
        : "",
  },
  {
    Header: "Party",
    id: "party",
    accessor: (row: any): string => row.party.name,
  },
  {
    Header: "Invoice Number",
    id: "supplierInvoiceNumber",
    accessor: "supplierInvoiceNumber",
  },
  {
    Header: "Invoice Date",
    id: "supplierInvoiceDate",
    accessor: (row: any): string =>
      row.supplierInvoiceDate
        ? formatDate(parseISO(row.supplierInvoiceDate), "dd/MM/yyyy")
        : "",
  },
  {
    Header: "Total",
    id: "total",
    accessor: (row: any): string =>
      row.total ? "Rs. " + parseFloat(row.total).toLocaleString("en-IN") : "",
  },
];

export const productionsColumns = [
  {
    Header: "Piece No.",
    id: "pieceNo",
    accessor: "pieceNo",
  },
  {
    Header: "Date",
    id: "dateOfProduce",
    accessor: (row: any): string =>
      row.dateOfProduce
        ? formatDate(parseISO(row.dateOfProduce), "dd/MM/yyyy")
        : "",
  },
  {
    Header: "Detail",
    id: "detail",
    accessor: (row: any): string => row.detail || "",
  },
  {
    Header: "Loom No.",
    id: "loomNo",
    accessor: (row: any): string => (row.loom ? row.loom.loomNo : ""),
  },
  {
    Header: "Beam No.",
    id: "beamNo",
    accessor: (row: any): string => row.beamNo || "",
  },
  {
    Header: "Length",
    id: "length",
    accessor: (row: any): string =>
      row.length ? (+row.length).toFixed(2) + " m" : "",
  },
  {
    Header: "Weight",
    id: "weight",
    accessor: (row: any): string =>
      row.weight ? (+row.weight).toFixed(2) + " kg" : "",
  },
  {
    Header: "GLM",
    id: "glm",
    accessor: (row: any): string | number =>
      row.weight && row.length
        ? Math.round((row.weight * 1000) / row.length)
        : 0,
    // eslint-disable-next-line react/display-name
    Cell: (cell: any): React.ReactElement => {
      const row = cell.row.original;
      const glm =
        row.weight && row.length
          ? Math.round((row.weight * 1000) / row.length)
          : 0;
      const qualityWeight = row.job ? row.job.qualityWeight : 0;

      return (
        <span
          className={
            !compareWeight(glm, qualityWeight, qualityWeight * glmScope)
              ? "text-danger"
              : ""
          }
        >
          {cell.value}
        </span>
      );
    },
  },
  {
    Header: "Dispatched",
    id: "isDispatched",
    accessor: (row: any): string => (row.isDispatched ? "Yes" : ""),
  },
];

export const jobsColumns = [
  { Header: "Job ID", id: "id", accessor: "id" },
  {
    Header: "Date",
    id: "dateOfJob",
    accessor: (row: any): string =>
      row.dateOfJob ? formatDate(parseISO(row.dateOfJob), "dd/MM/yyyy") : "",
  },
  {
    Header: "Fabric",
    id: "itemName",
    accessor: (row: any): string => (row.item ? row.item.name : ""),
  },
  {
    Header: "Loom",
    id: "loomNo",
    accessor: (row: any): string => row.loom && row.loom.loomNo,
  },
  {
    Header: "Beam No",
    id: "beamNo",
    accessor: (row: any): string =>
      row.beamNo1 +
      (row.beamNo2 ? `, ${row.beamNo2}` : "") +
      (row.beamNo3 ? `, ${row.beamNo3}` : ""),
  },
  {
    Header: "Beam Length",
    id: "beamLength",
    accessor: "beamLength",
  },
  {
    Header: "Reed Space",
    id: "reedSpace",
    accessor: "reedSpace",
  },
  {
    Header: "Ends",
    id: "epi",
    accessor: "epi",
  },
  {
    Header: "Picks",
    id: "ppi",
    accessor: "ppi",
  },
  {
    Header: "Q Weight",
    id: "qualityWeight",
    accessor: "qualityWeight",
  },
];

export const issuedSparesColumns = [
  {
    Header: "Date Of Issue",
    id: "dateOfIssue",
    accessor: (row: any): string =>
      row.dateOfIssue
        ? formatDate(parseISO(row.dateOfIssue), "dd/MM/yyyy")
        : "",
  },
  {
    Header: "Item",
    id: "item",
    accessor: (row: any): string => row.item.name,
  },
  {
    Header: "Loom",
    id: "loomNo",
    accessor: (row: any): string => row.loom.loomNo,
  },
  {
    Header: "Quantity",
    id: "quantity",
    accessor: "quantity",
  },
  {
    Header: "Remarks",
    id: "remarks",
    accessor: "remarks",
  },
];

export const saleOrdersColumns = [
  {
    Header: "Date",
    id: "dateOfOrder",
    accessor: (row: any): string =>
      row.dateOfOrder
        ? formatDate(parseISO(row.dateOfOrder), "dd/MM/yyyy")
        : "",
  },
  {
    Header: "Item",
    id: "item",
    accessor: (row: any): string => row.item.name,
  },
  {
    Header: "Loom",
    id: "loomNo",
    accessor: (row: any): string => row.loom.loomNo,
  },
  {
    Header: "Quantity",
    id: "quantity",
    accessor: "quantity",
  },
  {
    Header: "Remarks",
    id: "remarks",
    accessor: "remarks",
  },
];

export const stockSummaryColumns = [
  {
    Header: "Name",
    id: "item",
    accessor: (row: any): string => (row.item ? row.item.name : ""),
  },
  {
    Header: "Opening",
    id: "opening",
    accessor: (row: any): string =>
      row.opening +
      " " +
      (row.item && row.item.unit ? row.item.unit.symbol : ""),
  },
  {
    Header: "In",
    id: "incoming",
    accessor: (row: any): string =>
      row.incoming +
      " " +
      (row.item && row.item.unit ? row.item.unit.symbol : ""),
  },
  {
    Header: "Out",
    id: "outgoing",
    accessor: (row: any): string =>
      row.outgoing +
      " " +
      (row.item && row.item.unit ? row.item.unit.symbol : ""),
  },
  {
    Header: "Balance",
    id: "balance",
    accessor: (row: any): string =>
      row.balance +
      " " +
      (row.item && row.item.unit ? row.item.unit.symbol : ""),
  },
];

export const stockByItemColumns = [
  {
    Header: "Date",
    id: "voucherDate",
    accessor: (row: any): string =>
      row.voucherDate
        ? formatDate(parseISO(row.voucherDate), "dd/MM/yyyy")
        : "",
  },
  {
    Header: "Purchase",
    id: "purchaseId",
    accessor: (row: any): string => row.purchaseId || "",
  },
  {
    Header: "Supplier",
    id: "party",
    accessor: (row: any): string => (row.party ? row.party.name : ""),
  },
  {
    Header: "Rate",
    id: "rate",
    accessor: (row: any): string => (row.rate ? "₹" + row.rate : ""),
  },
  {
    Header: "InStock",
    id: "incoming",
    accessor: (row: any): string =>
      row.incoming ? row.incoming + " " + row.originalItem.unit.symbol : "",
  },
  {
    Header: "Issued ID",
    id: "issuedToId",
    accessor: (row: any): string => row.issuedToId || "",
  },
  {
    Header: "Issued To",
    id: "issuedToItem",
    accessor: (row: any): string =>
      row.issuedToItem ? row.issuedToItem.name || row.issuedToItem.loomNo : "",
  },
  {
    Header: "OutStock",
    id: "outgoing",
    accessor: (row: any): string =>
      row.outgoing ? row.outgoing + " " + row.originalItem.unit.symbol : "",
  },
];

export const materialInsColumns: Column<Record<string, unknown>>[] = [
  {
    Header: "ID",
    id: "id",
    accessor: "id",
  },
  {
    Header: "Date",
    id: "voucherDate",
    accessor: (row: any): string =>
      row.voucherDate
        ? formatDate(parseISO(row.voucherDate), "dd/MM/yyyy")
        : "",
  },
  {
    Header: "Party",
    id: "party",
    accessor: (row: any): string => (row.party ? row.party.name : ""),
  },
  {
    Header: "Total Pieces",
    id: "totalPieces",
    accessor: "totalPieces",
  },
  {
    Header: "Total Length (mtr)",
    id: "totalLength",
    accessor: (row: any): string =>
      row.totalLength ? (+row.totalLength).toFixed(2) + " m" : "",
  },
  {
    Header: "Total Weight (kg)",
    id: "totalWeight",
    accessor: (row: any): string =>
      row.totalWeight ? (+row.totalWeight).toFixed(2) + " kg" : "",
  },
];

export const roleAccess: any = {
  entry: ["productions"],
};
