import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Icon, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";

type Props = {
  isEditMode?: boolean;
  onDeleteClick?: () => void;
  onResetClick?: () => void;
  cancelPath?: string;
  isPrintVisible?: boolean;
  isDisabled?: boolean;
  loadingSubmit?: boolean;
  loadingDelete?: boolean;
};

function ButtonGroup({
  isEditMode,
  onDeleteClick,
  onResetClick,
  cancelPath = "",
  isPrintVisible,
  isDisabled,
  loadingSubmit,
  loadingDelete,
}: Props): React.ReactElement {
  const navigate = useNavigate();
  return (
    <Stack direction="row" spacing={1} displayPrint="none">
      {!isDisabled ? (
        <LoadingButton
          color="primary"
          type="submit"
          loading={loadingSubmit}
          variant="contained"
        >
          <Icon>done</Icon>
        </LoadingButton>
      ) : null}
      <LoadingButton
        color="info"
        onClick={() => navigate(cancelPath)}
        variant="contained"
      >
        <Icon>cancel</Icon>
      </LoadingButton>
      {!isEditMode && onResetClick ? (
        <Button color="error" onClick={onResetClick} variant="contained">
          <Icon>refresh</Icon>
        </Button>
      ) : null}
      {isEditMode && !isDisabled ? (
        <LoadingButton
          color="error"
          variant="contained"
          onClick={onDeleteClick}
          loading={loadingDelete}
        >
          <Icon>delete</Icon>
        </LoadingButton>
      ) : null}

      {isEditMode && !isDisabled && isPrintVisible ? (
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            window.print();
          }}
        >
          <Icon>print</Icon>
        </Button>
      ) : null}
    </Stack>
  );
}

export default ButtonGroup;
