import * as React from "react";
import { Autocomplete as MuiAutocomplete, TextField } from "@mui/material";
import { useState } from "react";
import { Loading } from ".";

interface Props {
  id: string;
  label: string;
  options: any[];
  labelKey: string;
  loadingOptions?: boolean;
  value: any;
  setValue: (x: any) => void;
  inputValue: string;
  setInputValue: (x: string) => void;
  minLength?: number;
  [rest: string]: unknown;
}

function Autocomplete({
  id,
  label,
  options,
  labelKey,
  loadingOptions,
  value,
  setValue,
  inputValue,
  setInputValue,
  minLength = 3,
  ...rest
}: Props): React.ReactElement {
  const [open, setOpen] = useState(false);
  // useEffect(() => {
  //   if (isVisible) {
  //     Array.prototype.map.call(
  //       document.getElementsByClassName("table-responsive"),
  //       (element) => {
  //         element.style.overflowX = "inherit";
  //       }
  //     );
  //   } else {
  //     Array.prototype.map.call(
  //       document.getElementsByClassName("table-responsive"),
  //       (element) => {
  //         element.style.overflowX = "auto";
  //       }
  //     );
  //   }
  // }, [isVisible]);

  return (
    <MuiAutocomplete
      open={!loadingOptions && inputValue.length >= minLength && open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={value}
      onChange={(_, newValue: any) => {
        setValue(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(_, newInputValue: string) => {
        setInputValue(newInputValue);
      }}
      id={id}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option[labelKey]}
      renderOption={(props, option) => <li {...props}>{option[labelKey]}</li>}
      loading={loadingOptions}
      loadingText={<Loading size={20} delay={100} />}
      options={Array.isArray(options) ? options : []}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          InputLabelProps={{ shrink: true }}
        />
      )}
      {...rest}
    />
  );
}

export default Autocomplete;
