import printStyles from "./print";

export default {
  "html, body": {
    height: "100%",
    minHeight: "100%",
  },

  body: {
    fontFamily: '"Roboto", sans-serif',
    margin: 0,
    padding: 0,
    overflow: "hidden",
    backgroundColor: "#fcfcfe",
  },

  "#root": {
    height: "100%",
  },

  'input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button':
    {
      webkitAppearance: "none",
      margin: 0,
    },

  "table.stripped": {
    tbody: {
      "tr:nth-of-type(even)": {
        backgroundColor: "rgba(0,0,0,0.05)",
      },
    },
  },

  ".table-bordered": {
    width: "100%",
    BorderCollapse: "collapse",
    tr: { border: "1px solid rgba(0, 0, 0, 0.42)", borderWidth: "1px 0" },
    "td,th": { border: "1px solidrgba(0, 0, 0, 0.42)", borderWidth: "0 1px" },
  },

  ".text-center": { TextAlign: "center" },

  ".pull-to-refresh": {
    ".lds-ellipsis": {
      div: { backgroundColor: "#3f50b5" },
    },
  },

  "@media print": printStyles,
};
