import { createRoot } from "react-dom/client";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  ApolloLink,
  concat,
} from "@apollo/client";
import { SnackbarProvider } from "notistack";
import cookie from "cookie";
import * as Sentry from "@sentry/browser";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, GlobalStyles } from "@mui/material";
import theme from "./assets/styles/theme";
import globalStyles from "./assets/styles/global";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import enIN from "date-fns/locale/en-IN";

import App from "./pages";

import config from "./auth/auth_config.json";
import packageJson from "../package.json";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: packageJson.version,
  });
}

// Set up our apollo-client to point at the server we created
// this can be local or a remote endpoint
const cache = new InMemoryCache({
  possibleTypes: {
    IssuedToItem: ["Item", "Loom"],
  },
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
  credentials: "include",
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      "client-name": "inventory-client",
      "client-version": packageJson.version,
      authorization: document.cookie && cookie.parse(document.cookie).token,
    },
  });

  return forward(operation);
});

const client = new ApolloClient({
  cache,
  link: concat(authMiddleware, httpLink),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
    },
    query: {
      fetchPolicy: "network-only",
    },
    mutate: {
      fetchPolicy: "network-only",
    },
  },
});

const onRedirectCallback = (appState: any) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

/**
 * Render our app
 * - We wrap the whole app with ApolloProvider, so any component in the app can
 *    make GraphqL requests. Our provider needs the client we created above,
 *    so we pass it as a prop
 * - We need a router, so we can navigate the app. We're using react router for this.
 *    The router chooses between which component to render, depending on the url path.
 *    ex: localhost:3000/login will render only the `Login` component
 */

createRoot(document.getElementById("root")!).render(
  <ApolloProvider client={client}>
    <Router>
      <Auth0Provider
        domain={config.domain}
        clientId={config.clientId}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: config.audience,
        }}
        onRedirectCallback={onRedirectCallback}
        useRefreshTokens
        cacheLocation="localstorage"
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enIN}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalStyles styles={globalStyles} />
            <SnackbarProvider
              autoHideDuration={2000}
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
            >
              <App />
            </SnackbarProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </Auth0Provider>
    </Router>
  </ApolloProvider>
);
