import * as React from "react";
import { TextField, Autocomplete as MuiAutocomplete } from "@mui/material";
import { Controller } from "react-hook-form";
import { useState } from "react";

import { Loading } from ".";

type Props = {
  name: string;
  label: string;
  control: any;
  required?: boolean;
  options: any[];
  labelKey: string;
  getOptionDisabled?: (option: any) => boolean;
  loadingOptions?: boolean;
  minLength?: number;
  onChange?: (x: any) => void;
  onInputChange?: (x: any) => void;
  [rest: string]: unknown;
  freeSolo?: boolean;
};

function FormAutocomplete({
  name,
  label,
  control,
  required,
  options,
  labelKey,
  getOptionDisabled,
  loadingOptions,
  minLength = 3,
  onChange,
  onInputChange,
  freeSolo,
  ...rest
}: Props): React.ReactElement {
  const [open, setOpen] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field }) => {
        return (
          <MuiAutocomplete
            {...rest}
            {...field}
            fullWidth
            freeSolo={freeSolo}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            value={field.value}
            onChange={(_, newValue: any) => {
              field.onChange(newValue);
              onChange && onChange(newValue);
            }}
            options={Array.isArray(options) ? options : []}
            isOptionEqualToValue={(option: any, value: any) => {
              return typeof option === "string"
                ? option === value
                : option.id === value.id;
            }}
            getOptionDisabled={getOptionDisabled}
            getOptionLabel={(option: any) => {
              return typeof option === "string" ? option : option[labelKey];
            }}
            renderOption={(props, option: any) => {
              return (
                <li {...props}>
                  {typeof option === "string" ? option : option[labelKey]}
                </li>
              );
            }}
            loading={loadingOptions}
            loadingText={<Loading size={20} delay={100} />}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  onFocus={(event: any) => {
                    params.inputProps.onFocus &&
                      params.inputProps.onFocus(event);
                    setOpen(false);
                  }}
                  onChange={(event: any) => {
                    params.inputProps.onChange &&
                      params.inputProps.onChange(event);

                    onInputChange && onInputChange(event.target.value);
                    setOpen(
                      event.target.value.length >= minLength ? true : false
                    );
                  }}
                  onBlur={(event: any) => {
                    params.inputProps.onBlur && params.inputProps.onBlur(event);
                    if (
                      freeSolo &&
                      (!field.value || (field.value && !field.value.id)) &&
                      event.target.value
                    ) {
                      onChange && onChange(event.target.value);
                    }
                    onInputChange && onInputChange("");
                  }}
                  variant="standard"
                  label={label}
                  required={required}
                  InputLabelProps={{ shrink: true }}
                />
              );
            }}
          />
        );
      }}
    />
  );
}

export default FormAutocomplete;
