import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link as RouterLink } from "react-router-dom";

import auth0Config from "../auth/auth_config.json";
import logo from "../assets/images/orion-logo-white.png";
import {
  Box,
  AppBar,
  Icon,
  IconButton,
  Theme,
  Toolbar,
  Link,
} from "@mui/material";

type Props = {
  sidebar?: boolean;
  displayPrint?: string;
  handleDrawerToggle?: () => void;
};

function Header({
  sidebar,
  displayPrint,
  handleDrawerToggle,
}: Props): React.ReactElement {
  const { isAuthenticated, logout, loginWithRedirect } = useAuth0();

  return (
    <Box
      component={AppBar}
      displayPrint={displayPrint}
      position="fixed"
      elevation={0}
      sx={{ zIndex: (theme: Theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar variant="dense">
        {sidebar ? (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <Icon>menu</Icon>
          </IconButton>
        ) : null}
        <Box sx={{ flexGrow: 1, textAlign: { xs: "center", md: "left" } }}>
          <Link component={RouterLink} to="/">
            <img src={logo} alt="logo" width="138" height="32" />
          </Link>
        </Box>
        <Box>
          {isAuthenticated ? (
            <IconButton
              edge="end"
              color="inherit"
              onClick={() =>
                logout({
                  // returnTo: window.location.origin,
                  // client_id: auth0Config.clientId,
                })
              }
              title="logout"
            >
              <Icon>logout</Icon>
            </IconButton>
          ) : (
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => loginWithRedirect({})}
              title="login"
            >
              <Icon>login</Icon>
            </IconButton>
          )}
        </Box>
      </Toolbar>
    </Box>
  );
}

export default Header;
