export default {
  "*": {
    backgroundColor: "transparent !important",
    color: "black !important",
  },

  "html, body": {
    fontSize: "12px",
    height: "auto",
  },

  body: {
    backgroundColor: "#ffffff !important",
  },

  "body, .page-container": {
    overflow: "visible",
  },

  // .col-md {
  //   flex: 1 0 0%;
  // }

  // .col-md-auto {
  //   flex: 0 0 auto;
  //   width: auto;
  // }

  // .col-md-1 {
  //   flex: 0 0 auto;
  //   width: 8.3333333333%;
  // }

  // .col-md-2 {
  //   flex: 0 0 auto;
  //   width: 16.6666666667%;
  // }

  // .col-md-3 {
  //   flex: 0 0 auto;
  //   width: 25%;
  // }

  // .col-md-4 {
  //   flex: 0 0 auto;
  //   width: 33.3333333333%;
  // }

  // .col-md-5 {
  //   flex: 0 0 auto;
  //   width: 41.6666666667%;
  // }

  // .col-md-6 {
  //   flex: 0 0 auto;
  //   width: 50%;
  // }

  // .col-md-7 {
  //   flex: 0 0 auto;
  //   width: 58.3333333333%;
  // }

  // .col-md-8 {
  //   flex: 0 0 auto;
  //   width: 66.6666666667%;
  // }

  // .col-md-9 {
  //   flex: 0 0 auto;
  //   width: 75%;
  // }

  // .col-md-10 {
  //   flex: 0 0 auto;
  //   width: 83.3333333333%;
  // }

  // .col-md-11 {
  //   flex: 0 0 auto;
  //   width: 91.6666666667%;
  // }

  // .col-md-12 {
  //   flex: 0 0 auto;
  //   width: 100%;
  // }

  a: {
    textDecoration: "none",
  },

  "thead th, tbody td": {
    padding: "0.5rem 0.25rem",
    verticalAlign: "top",
  },
};
