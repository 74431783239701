import { createTheme } from "@mui/material/styles";

// A custom theme for this app
let theme = createTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#3f50b5",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: { [theme.breakpoints.up("md")]: { minWidth: "167px" } },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "120%",
          fontWeight: "bold",
          color: theme.palette.text.primary,
        },
        asterisk: {
          "@media print": {
            display: "none",
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          "@media print": {
            display: "none",
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        "grid-md-true": {
          "@media print": {
            flexBasis: 0,
            flexGrow: 1,
            maxWidth: "100%",
          },
        },
        "grid-md-auto": {
          "@media print": {
            flexBasis: "auto",
            flexGrow: 0,
            flexShrink: 0,
            maxWidth: "none",
            width: "auto",
          },
        },
        "grid-md-1": {
          "@media print": {
            flex: "0 0 auto",
            width: "8.3333333333%",
          },
        },
        "grid-md-2": {
          "@media print": {
            flex: "0 0 auto",
            width: "16.6666666667%",
          },
        },
        "grid-md-3": {
          "@media print": {
            flex: "0 0 auto",
            width: "25%",
          },
        },
        "grid-md-4": {
          "@media print": {
            flex: "0 0 auto",
            width: "33.3333333333%",
          },
        },
        "grid-md-5": {
          "@media print": {
            flex: "0 0 auto",
            width: "41.6666666667%",
          },
        },
        "grid-md-6": {
          "@media print": {
            flexBasis: "50%",
            flexGrow: 0,
            maxWidth: "50%",
          },
        },
        "grid-md-7": {
          "@media print": {
            flex: "0 0 auto",
            width: "58.3333333333%",
          },
        },
        "grid-md-8": {
          "@media print": {
            flex: "0 0 auto",
            width: "66.6666666667%",
          },
        },
        "grid-md-9": {
          "@media print": {
            flex: "0 0 auto",
            width: "75%",
          },
        },
        "grid-md-10": {
          "@media print": {
            flex: "0 0 auto",
            width: "83.3333333333%",
          },
        },
        "grid-md-11": {
          "@media print": {
            flex: "0 0 auto",
            width: "91.6666666667%",
          },
        },
        "grid-md-12": {
          "@media print": {
            flexBasis: "100%",
            flexGrow: 0,
            maxWidth: "100%",
          },
        },
      },
    },
  },
});

export default theme;
