import * as React from "react";
import { Toolbar } from "@mui/material";
import { Box } from "@mui/system";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDidUpdate } from "rooks";
import PullToRefresh from "react-simple-pull-to-refresh";
import { isMobile } from "react-device-detect";

import { Header, Sidebar } from ".";

const drawerWidth = 200;

type Props = {
  children?: React.ReactNode;
  sidebar?: boolean;
  roleAccess?: string[];
};

function PageContainer({
  sidebar = false,
  roleAccess,
}: Props): React.ReactElement {
  const location = useLocation();
  const navigate = useNavigate();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = (open?: any) => {
    setMobileOpen(typeof open === "boolean" ? open : !mobileOpen);
  };
  useDidUpdate(() => {
    handleDrawerToggle(false);
  }, [location]);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <Header
        sidebar={sidebar}
        handleDrawerToggle={handleDrawerToggle}
        displayPrint="none"
      />
      {sidebar ? (
        <Sidebar
          drawerWidth={drawerWidth}
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          displayPrint="none"
          roleAccess={roleAccess}
        />
      ) : null}
      <Box component="main" sx={{ flexGrow: 1 }} overflow="auto">
        <Toolbar variant="dense" sx={{ displayPrint: "none" }} />
        <div id="topView" />
        <Box p={1}>
          <PullToRefresh
            isPullable={isMobile}
            onRefresh={async () => navigate(0)}
            className="pull-to-refresh"
          >
            <Outlet />
          </PullToRefresh>
        </Box>
      </Box>
    </Box>
  );
}

export default PageContainer;
