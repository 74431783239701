export function compareWeight(
  principal: number,
  comparator: number,
  scope = 0
): boolean {
  return !(principal < comparator - scope || principal > comparator + scope);
}

export function calculateTotalLength(details: any[]): number {
  return details.reduce(
    (acc: any, curr: any) => acc + (curr.length ? +curr.length : 0),
    0
  );
}

export function calculateTotalWeight(details: any[]): number {
  return details.reduce(
    (acc: any, curr: any) => acc + (curr.weight ? +curr.weight : 0),
    0
  );
}

export function calculateShrinkage(
  totalLength: number,
  dispatchedLength: number
): number {
  if (!dispatchedLength) return 0;
  return 100 - (+totalLength / +dispatchedLength) * 100;
}
